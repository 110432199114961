<template>
  <div class="shareLike">
    <!-- 顶部面包屑 -->
    <div class="top_breadcrumb">
      <i class="el-icon-arrow-left" @click="$router.go(-1)"></i>
      <span @click="switchContent(1)" :class="{ active: index == 1 }"
        >{{ likeCount }}赞</span
      >
      |
      <span @click="switchContent(2)" :class="{ active: index == 2 }"
        >{{ shareCount }}转发</span
      >
    </div>

    <div class="null-box" v-if="!listContent.length">
      <img
        :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
        alt=""
        srcset=""
      />
      <div class="title">暂无数据</div>
    </div>

    <!-- 列表内容 -->
    <ul class="listContent" v-else>
      <li v-for="item in listContent" :key="item.c_id">
        <div class="left">
          <img
            @click="$router.push('/found-personal?id=' + item.id)"
            :src="item.avatar ? item.avatar : require('@image/default.png')"
            alt=""
          />
          <div class="name">
            <p>{{ item.nickname }}</p>
            <p>{{ item.create_time }}</p>
          </div>
        </div>
        <div class="right" v-if="userInfo.id != item.id">
          <el-button
            @click="attention(item.id)"
            v-if="item.status == 1"
            type="primary"
            >关注</el-button
          >
          <el-button @click="attention(item.id)" v-if="item.status == 2"
            >已关注</el-button
          >
          <el-button @click="attention(item.id)" v-if="item.status == 3"
            >互相关注</el-button
          >
        </div>
      </li>
    </ul>

    <!-- 分页 -->
    <div class="paging">
      <el-pagination
        hide-on-single-page
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";

export default {
  name: "shareLike",
  data() {
    return {
      index: 1,
      likeCount: 0,
      shareCount: 0,
      page: 1,
      id: 0,
      platform: "",
      listContent: [],
      pageSize: 1,
      total: 10,
    };
  },
  created() {
    this.index = this.$route.query.index;
    this.likeCount = this.$route.query.likeCount;
    this.shareCount = this.$route.query.shareCount;
    this.id = this.$route.query.id;
    this.platform = this.$route.query.platform;
  },

  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },

  mounted() {
    this.aboutList();
  },

  methods: {
    // tab切换
    switchContent(index) {
      this.index = index;
      this.aboutList();
    },

    // 点赞转发列表
    async aboutList() {
      let data = {
        discover_id: this.id,
        page: this.page,
        type: this.index,
      };
      if (this.platform) {
        data.platform = this.platform;
      }
      const res = await this.$findApi.aboutList(data);

      if (res.code == 200) {
        this.listContent = res.data.list;
        this.pageSize = res.data.listrows;
        this.total = this.index == 1 ? res.data.likecount : res.data.sharecount;
      }
    },

    //关注
    async attention(id) {
      let { data, code, message } = await this.$userApi.postDiscoverAttention({
        attention_id: id,
      });
      if (code == 200) {
        this.$message.success(data);
        this.aboutList();
      } else {
        this.$message.error(message);
      }
    },

    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.shareLike {
  background: #f2f2f2;

  > .top_breadcrumb {
    width: 1200px;
    margin: 0 auto;
    margin-top: 15px;
    background: $--themeColor;
    height: 40px;
    line-height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 0 10px;
    box-sizing: border-box;

    > i {
      font-weight: 700;
      font-size: 18px;
    }

    > span {
      margin: 0 10px;
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .listContent {
    background: #fff;
    width: 1200px;
    margin: 0 auto;
    margin-top: 15px;
    padding: 20px 15px;
    box-sizing: border-box;

    > li {
      border-bottom: 1px solid #f2f2f2;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > .left {
        display: flex;
        align-items: center;

        > img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 10px;
        }

        > .name {
          > p {
            margin-bottom: 5px;
            font-size: 16px;

            &:last-child {
              color: #8f8f8f;
              font-size: 12px;
            }
          }
        }
      }

      > .right {
        button {
          width: 100px;
          height: 35px;
          line-height: 35px;
          padding: 0;
          font-size: 16px;
        }
      }

      &:first-child {
        border-top: 1px solid #f2f2f2;
      }
    }
  }
}

/deep/ {
  .el-pager li {
    background: #f2f2f2;
  }

  .el-pagination .btn-prev,
  .el-pagination .btn-next {
    background: #f2f2f2;
  }
}

.active {
  opacity: 1 !important;
}
</style>